import { Notification } from '../lib';
import { Transition } from '@headlessui/react';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';

interface NotificationProp {
    notificationArray: Notification[];
}
export default function Notifications({ notificationArray }: NotificationProp) {
    return (
        <div className="fixed bottom-5 right-5 z-999999 flex flex-col gap-4">
            {notificationArray &&
                notificationArray.map((notification: Notification, i) => {
                    return (
                        <Transition
                            appear
                            show={notificationArray.length > 0 ? true : false}
                            // show={true}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            key={'notification-' + i}
                        >
                            {notification.success && (
                                <div
                                    className={`flex w-full items-center gap-2 border-l-6 border-success bg-white p-4 shadow-md dark:bg-[#1B1B24] ${'flex'}`}
                                >
                                    <AiFillCheckCircle
                                        className={'text-success'}
                                        size={30}
                                    />
                                    <h5 className="whitespace-nowrap text-lg font-semibold text-success dark:text-success ">
                                        {notification.message}
                                    </h5>
                                </div>
                            )}
                            {!notification.success && (
                                <div
                                    className={`flex w-full items-center gap-2 border-l-6 border-danger bg-white p-4 shadow-md dark:bg-[#1B1B24] ${'flex'}`}
                                >
                                    <AiFillCloseCircle
                                        className={'text-danger'}
                                        size={30}
                                    />
                                    <h5 className="whitespace-nowrap text-lg font-semibold text-danger dark:text-success ">
                                        {notification.message}
                                    </h5>
                                </div>
                            )}
                        </Transition>
                    );
                })}
        </div>
    );
}
