import { NotificationContextType, Notification } from '../../lib';
import { createContext, ReactNode, useState } from 'react';
import Notifications from '../Notifications';
interface NotificationProviderProps {
    children: ReactNode;
}

export const NotificationContext =
    createContext<NotificationContextType | null>(null);

export default function NotificationProvider({
    children,
}: NotificationProviderProps) {
    const [notificationArray, setNotificationArray] = useState<Notification[]>(
        []
    );

    function clearNotification() {
        setNotificationArray([]);
    }

    function pushNotification(newNotification: Notification) {
        let notifications = [...notificationArray];
        if (notifications.length + 1 > 5) {
            notifications.pop();
        }
        notifications.push(newNotification);
        setNotificationArray(notifications);
        setTimeout(() => {
            clearNotification();
        }, 3000);
    }
    return (
        <NotificationContext.Provider
            value={{
                pushNotification: pushNotification,
                clearNotification: clearNotification,
            }}
        >
            <Notifications notificationArray={notificationArray} />
            {children}
        </NotificationContext.Provider>
    );
}
